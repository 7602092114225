<template>
  <div>
    <div style="margin-bottom: 24px">
      <a-card :bordered="false">
        <DescriptionList
          :title="this.title"
          size="large"
          style="margin-bottom: -24px"
          class="feeEditLabelWidth"
        >
          <a-card :bordered="false" style="margin-left: -16px">
            <div class="table-operator">
              <a-form layout="inline" :form="form">
                <a-row :gutter="5" style="line-height: 4">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="账单类型">
                      <a-select
                        style="width: 240px"
                        v-decorator="[
                          'billCostType',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ],
                            initialValue: feeDetail.billType
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in billTypeList"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="选择门店">
                      <a-select
                        @change="getKitchen"
                        style="width: 240px"
                        v-decorator="[
                          'facilityId',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ],
                            initialValue: feeDetail.facilityName
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in facilityList"
                          :key="item.facilityId"
                        >
                          {{ item.facilityName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="选择客户">
                      <a-select
                        style="width: 240px"
                        mode="multiple"
                        v-decorator="[
                          'tenantIdList',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ],
                            initialValue: feeDetail.tenantName
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option
                          v-for="item in personList"
                          :key="item.tenantId"
                        >
                          {{ item.tenantName }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否公摊">
                      <a-select
                        style="width: 240px"
                        v-decorator="[
                          'isPublic',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ],
                            initialValue: feeDetail.isShare
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option value="1">是</a-select-option>
                        <a-select-option value="2">否</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="金额">
                      <a-select
                        style="width: 98px"
                        v-decorator="[
                          'handelType',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ]
                          }
                        ]"
                        placeholder="请选择"
                      >
                        <a-select-option value="1">扣除</a-select-option>
                        <a-select-option value="2">补贴</a-select-option>
                      </a-select>
                      --
                      <a-input
                        style="width: 120px"
                        v-decorator="[
                          'amountCost',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: feeDetail.amountCost
                          }
                        ]"
                        placeholder="请输入        元"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :md="24" :sm="24">
                    <a-form-item label="备注">
                      <a-input
                        style="width: 960px"
                        v-decorator="[
                          'remark',
                          {
                            rules: [
                              {
                                required: false,
                                message: '请输入！'
                              }
                            ],
                            initialValue: feeDetail.remark
                          }
                        ]"
                        placeholder="请输入"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form>
            </div>
          </a-card>
        </DescriptionList>
      </a-card>
    </div>
    <a-card :bordered="false">
      <div>
        <label class="listTitle" style="margin-bottom: 24px;">预览费用</label>
        <!--        <a-form layout="inline" style="margin-bottom: 24px; text-align: right">-->
        <!--          <a-button icon="plus" type="primary" @click="addFin"-->
        <!--            >新增费用</a-button-->
        <!--          >-->
        <!--        </a-form>-->
      </div>
      <a-table
        rowKey="id"
        :pagination="pagination"
        :columns="viewcolumns"
        :dataSource="viewdata"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </a-table>
    </a-card>
    <div style="margin-top: 8px">
      <a-card :bordered="false">
        <div class="steps-action">
          <a-button @click="cancel">
            取消
          </a-button>
          <a-button type="primary" style="margin-left: 8px" @click="conserve">
            保存
          </a-button>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
  
import { modal } from "ant-design-vue";
import Bus from "@/bus";
import axios from "axios";
import { defaultPagination } from "@/utils/pagination";

export default {
  name: "financeFeeNew",
  components: {
    DescriptionList
  },
  data() {
    return {
      thisdetailId: this.$route.query.thisdetailId,
      pagination: defaultPagination(() => {}),
      viewdata: [],
      viewcolumns: [
        {
          title: "客户名称",
          dataIndex: "name",
          key: "name"
        },
        {
          title: "档口名称",
          dataIndex: "key",
          key: "key"
        },
        {
          title: "费用类型",
          dataIndex: "",
          key: ""
        },
        {
          title: "费用金额",
          dataIndex: "",
          key: ""
        }
      ],
      //账单类型
      billTypeList: [
        {
          id: 1,
          name: "首期款账单"
        },
        {
          id: 2,
          name: "改造费用账单"
        },
        {
          id: 3,
          name: "退租结算账单"
        },
        {
          id: 4,
          name: "房租账单"
        },
        {
          id: 5,
          name: "经营费用账单"
        }
      ],
      form: this.$form.createForm(this),
      feeDetail: {
        billType: undefined,
        feeDetail: undefined,
        facilityName: undefined
      },
      title: "",
      nowFacility: "",
      //所在门店
      facilityList: [],
      // 客户名称
      personList: []
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/finance/financeFee"
      });
    });
  },
  methods: {
    //所在门店变化的时候
    getKitchen(val) {
      // 如果查询到的客户为空，清空它们之前的选中
      const {
        form: { setFieldsValue }
      } = this;
      setFieldsValue({
        tenantIdList: []
      });
      this.personList = [];
      this.nowFacility = val;
      this.getTenant(val);
    },
    // 客户名称
    getTenant(val) {
      this.personList = [];
      axios({
        url:   this.$baseUrl + "/tenantStore/tenant",
        method: "GET",
        params: {
          facilityId: val
        }
      }).then(res => {
        if (res.data.rows) {
          this.personList = res.data.rows;
        }
      });
    },
    init() {
      if (this.thisdetailId) {
        this.title = "编辑费用";
        this.tenantCostQuery();
      } else {
        this.title = "新建费用";
      }
      // 所在门店
      axios({
        url:   this.$baseUrl + "/tenantStore/allFacility",
        method: "GET"
      }).then(res => {
        if (res.data.rows) {
          this.facilityList = res.data.rows;
        }
      });
    },
    tenantCostQuery() {
      axios({
        url:   this.$baseUrl + "/tenantCost/query",
        method: "GET",
        params: {
          id: this.thisdetailId
        }
      }).then(res => {
        if (res.data.obj) {
          if (res.data.obj) {
            this.feeDetail = res.data.obj;
            this.getTenant(this.feeDetail.facilityId);
          }
        }
      });
    },
    //保存
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          modal.confirm({
            title: that.newOrEdit(),
            onOk() {
              that.tenantCostQuerySave(values);
              that.$router.push({ path: "/finance/financeFee" });
              return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log("Oops errors!"));
            },
            onCancel() {}
          });
        }
      });
    },
    tenantCostQuerySave(values) {
      axios({
        url:   this.$baseUrl + "/tenantCost/save",
        method: "POST",
        withCredentials: true,
        data: {
          ...values,
          id: this.thisdetailId
        }
      }).then(res => {
        console.log("res", res);
      });
    },
    newOrEdit() {
      if (!this.thisdetailId) {
        return "该页面信息是否保存？";
      } else {
        return "该页面信息已编辑，是否保存？";
      }
    },
    cancel() {
      this.$router.push({ path: "/finance/financeFee" });
    }
  }
};
</script>

<style scoped>
.steps-action {
  float: right;
  /*margin-top: 24px;*/
}
</style>
